<template lang="pug">
    .agent-contact
        v-card.agent-contact.pa-3
              .mb-6 Get in touch about these properties with:
              .body-2
                .name.font-weight-bold.mb-3.body-1.text-uppercase.mb-4 {{agentName}}
                v-row.phone-1(v-if="agent.primaryPhone")
                    v-col(cols="1" md="2" lg="1")
                        v-icon mdi-phone-forward
                    v-col(cols="9") {{agent.primaryPhone}}
                v-row.phone-2(v-if="agent.secondaryPhone")
                    v-col(cols="1" md="2" lg="1")
                        v-icon mdi-phone
                    v-col(cols="9") {{agent.secondaryPhone}}
                v-row.email(v-if="agent.email")
                    v-col(cols="1" md="2" lg="1")
                        v-icon mdi-email
                    v-col(cols="9")
                        a(:href="'mailto:' + agent.email") {{agent.email}}
                
                template(v-if="agent.contactNotes")
                    v-divider.my-3
                    .contact-notes {{agent.contactNotes}}
</template>

<script>
export default {
  name: "AgentContact",
  props: {
    agent: { type: Object, required: true },
    agentName: { type: String, required: true }
  }
};
</script>
