<template lang="pug">
    .proposal-wrapper
        slot(name="top")
        v-row(dense).my-0.flex-column-reverse.flex-md-row
            v-col(cols="12" :md="isCollapsed ? 11 : 8", :lg="isCollapsed ? 11 : 7")
                .mb-6
                    slot(name="left")
            v-col(cols="12" :md="isCollapsed ? 1: 4", :lg="isCollapsed ? 1 : 5" :class="{'box-shadow': !isCollapsed && !$route.path.includes('dashboard')}").pa-0.primary.darken-2
              .ma-2(v-if="$vuetify.breakpoint.mdAndUp")
                v-btn(icon dark @click="isCollapsed = !isCollapsed")
                  v-icon(color="white") {{isCollapsed ? "mdi-arrow-collapse-left" : "mdi-arrow-collapse-right"}}
              
              template(v-if="isCollapsed")
                .d-flex.flex-column.justify-center.text-center.mt-12(v-if="activeProposal")
                  v-tooltip(left v-for="property, idx in activeProposal.properties", :key="idx")
                    template(v-slot:activator="{ on, attrs }")
                      a.link(v-on="on", :href="property.url", :class="{'text--lighten-5': !linkIsActive(idx)}" @click.prevent="$emit('click', idx)").text-decoration-none.display-2.primary--text.mb-4 {{idx+1}}
                    span {{property.title}} – {{property.price}}
              
              template(v-else)
                .pa-6.pa-md-0
                  .d-flex.flex-column.justify-space-between(:style="{'min-height':'100vh'}")
                      v-card.pa-4.pa-md-12(tile outlined color="primary darken-2").white--text
                        Sidebar
                        slot(name="right")

</template>

<script>
import { get } from "vuex-pathify";
import Sidebar from "@/components/Proposals/Viewer/Sidebar";
export default {
  name: "ProposalMakerWrapper",
  components: { Sidebar },
  props: {},
  data() {
    return {
      isCollapsed: false
    };
  },
  computed: {
    ...get({ activeProposal: "ProposalViewer/activeProposal" })
  },
  methods: {
    linkIsActive(idx) {
      return this.$route.query.property == idx + 1;
    }
  }
};
</script>

<style lang="scss" scoped>
h2.mb-6.display-1 {
  line-height: 1.4;
  font-weight: 300;
  //   font-size: 1.2rem;
}

@media (min-width: 600px) {
  h2 {
    line-height: 1.7;
  }

  .box-shadow {
    box-shadow: -6px -6px 5px rgba(0, 0, 0, 0.25);
  }
}
</style>
