<template lang="pug">
    .sidebar
        template(v-if="activeProposal && activeProposal.properties && activeProposal.properties.length > 0")
            v-card.pa-4(width="200" outlined color="white" shaped).mx-auto.mb-6
                a(:href="activeProposal.branding.url" target="_blank" rel="noopener")
                  v-img(:src="activeProposal.branding.logo" eager)

            h1.display-2.text-center.mb-12.text-h5.text-md-h4 {{agentName}} from {{activeProposal.branding.name || 'the agency'}} has shared {{activeProposal.properties.length}} properties with you
            .intro(v-if="activeProposal.intro").mb-12
                .d-flex.align-start
                    v-icon(color="white" size="3rem").mr-3 mdi-comment-quote
                    span.break-spaces.text-h6.font-weight-regular {{introIsCollapsed && activeProposal.intro.length >= 25 ? activeProposal.intro.substr(0,25) + '...' : activeProposal.intro}}
                .text-center(v-if="introIsCollapsed && activeProposal.intro.length >= 25")
                    v-btn( plain dark @click="introIsCollapsed = false").mt-4 Read more
                        v-icon.ml-2 mdi-chevron-down

            template(v-if="activeProposal.properties && activeProposal.properties.length > 0")
                v-row.links(v-for="property, idx in activeProposal.properties", :dense="$vuetify.breakpoint.mdAndDown", :class="{'mb-8': $vuetify.breakpoint.mdAndDown}" :key="property.url")
                    v-col(cols="1")
                        a(:class="{active: idx === activePropertyId}", @click="changeActivePropertyTo(idx)").num.text-h4 {{idx + 1}}
                    v-col(cols="11")
                        a(:class="{active: idx === activePropertyId}", @click="changeActivePropertyTo(idx)").text-h6
                            | {{property.title}}

            
            AgentContact(v-if="agent && agentName", :agent="agent", :agentName="agentName")
            .agency-branding.mt-12
              h3.name.mb-2 {{activeProposal.branding.name}}
              .description {{activeProposal.branding.description}}
              v-btn(text dark :href="activeProposal.branding.url" target="_blank" rel="noopener").mt-2
                v-icon.mr-2 mdi-arrow-right
                | Agency Website
</template>

<script>
import AgentContact from "@/components/Proposals/Viewer/AgentContact.vue";
import { get, call } from "vuex-pathify";
export default {
  name: "Sidebar",
  components: { AgentContact },

  data() {
    return {
      introIsCollapsed: true
    };
  },
  mounted() {},
  computed: {
    ...get({
      activePropertyId: "ProposalViewer/activePropertyId",
      activeProposal: "ProposalViewer/activeProposal"
    }),
    agent() {
      return this.activeProposal && this.activeProposal.agent
        ? this.activeProposal.agent
        : {};
    },
    agentName() {
      if (!this.agent || !this.agent.firstName) return "your agent";

      return this.agent.lastName
        ? this.agent.firstName + " " + this.agent.lastName
        : this.agent.firstName;
    }
  },
  methods: {
    ...call({ changeActivePropertyTo: "ProposalViewer/changeActivePropertyTo" })
  }
};
</script>

<style lang="scss" scoped>
a {
  color: white;
  opacity: 0.8;
}
a.active {
  color: #394959;
  opacity: 1;
}
</style>
