<template lang="pug">
  v-container.primary.darken-2(fluid)
    v-row.sharing-link(v-if="activeProposal" align="center" justify="center").py-3
      v-col(cols="12" md="12" lg="1").text-center
        span.white--text Sharing link
      v-col(cols="12" md="12" lg="7")
        v-text-field(outlined label="Sharing Link" readonly :placeholder="link" :value="link" dark hide-details)
      v-col(cols="12" md="4" lg="2").flex-row.justify-center
          v-btn(tile outlined dark block x-large :color="buttonColor" @click="handleCopy") {{buttonText}}
      v-col(cols="12" md="4" lg="2")
          v-btn(tile depressed dark block x-large to="/crm/Leads") Back to leads
</template>

<script>
import { get } from "vuex-pathify";
import { copyToClipboard } from "@/helpers";
export default {
  name: "PropertyNotes",
  data() {
    return {
      buttonText: "Copy Link",
      buttonColor: null
    };
  },
  computed: {
    ...get({
      activeProposal: "ProposalViewer/activeProposal"
    }),
    link() {
      return window.location.href.split("/dashboard").join("");
    }
  },
  methods: {
    handleCopy() {
      copyToClipboard(this.link);
      this.buttonText = "Copied!";
      this.buttonColor = "success";

      setTimeout(() => {
        this.buttonText = "Copy Link";
        this.buttonColor = null;
      }, 2000);
    }
  }
};
</script>
