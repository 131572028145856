<template lang="pug">
    .dialog-image-preview
        v-dialog(v-model="showPreviewImage" :width="maxWidth" :overlay-opacity="0.95")
            v-sheet(:width="maxWidth")
                v-img(:src="previewImage" @click="showPreviewImage=false" contain title="Click to close image preview")
</template>

<script>
import { sync } from "vuex-pathify";
export default {
  name: "DialogImagePreview",
  computed: {
    ...sync({
      showPreviewImage: "Cleaner/showPreviewImage",
      previewImage: "Cleaner/previewImage"
    }),
    maxWidth() {
      if (this.$vuetify.breakpoint.mdAndUp) return "55vw";
      else {
        return "90vw";
      }
    }
  }
};
</script>
