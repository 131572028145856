<template lang="pug">
  v-overlay(:value="true")
    v-progress-circular(indeterminate size="64")
</template>

<script>
export default {
  name: "Loader",
  computed: {}
};
</script>
