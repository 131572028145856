<template lang="pug">
  ProposalWrapper(@click="changeActivePropertyTo($event)")
    template(#top)
      template(v-if="isDashboard")
        SharingLink#very-top
        Analytics(v-if="activeProposal")
    template(#left)
        Loader(v-if="!initFinished", :value="!initFinished")
        ProposalNotFound(v-else-if="!activeProposal || !activeProposal.properties || activeProposal.properties.length < 1")
        
        #content-top.pa-5(v-if="activeProposal && activeProposal.properties")
            ProposalIsExpired(v-if="activeProposal && linkIsExpired")
                
            template(v-else)
                PropertyNotes(v-if="activeProposal.notes[activePropertyId]")
                v-fade-transition
                  CleanPropertyPage(:label="'Property ' + (activePropertyId + 1)", lazySrc="/logo-app.png", :elems="activeProperty || activeProposal.properties[0]", :key="activeProperty ? activeProperty.url : new Date().toString()", @click:translate="translate", @click:navToAgencySiteSourceUrl="goToSourcePage", @click:image="viewImage")

                ButtonsPrevNext
                    
</template>

<script>
import { get, sync, call } from "vuex-pathify";
import { waitForElemToExist } from "@/helpers";

import ProposalWrapper from "@/components/Proposals/Viewer/ProposalWrapper.vue";
import Loader from "@/components/Proposals/Viewer/Loader.vue";
import Analytics from "@/components/Proposals/Analytics/Analytics.vue";
import ProposalNotFound from "@/components/Proposals/Viewer/ProposalNotFound.vue";
import ProposalIsExpired from "@/components/Proposals/Viewer/ProposalIsExpired.vue";
import PropertyNotes from "@/components/Proposals/Viewer/PropertyNotes.vue";
import ButtonsPrevNext from "@/components/Proposals/Viewer/ButtonsPrevNext.vue";
import SharingLink from "@/components/Proposals/Viewer/SharingLink.vue";

import CleanPropertyPage from "@/components/CleanPropertyPage.vue";

export default {
  name: "Proposal",
  components: {
    ProposalWrapper,
    Loader,
    Analytics,
    ProposalNotFound,
    ProposalIsExpired,
    PropertyNotes,
    ButtonsPrevNext,
    SharingLink,
    CleanPropertyPage
  },
  watch: {
    isDashboard(newVal) {
      if (newVal) {
        this.scrollToTop();
      }
    }
  },
  data() {
    return {
      agencyId: null,
      proposalId: null,
      initFinished: false,
      url: window.location.origin + window.location.pathname
    };
  },
  created() {
    this.init();
    if (!this.$route.path.includes("/dashboard"))
      window.addEventListener("beforeunload", this.beforeWindowUnload);
  },
  mounted() {},
  beforeDestroy() {
    window.removeEventListener("beforeunload", this.beforeWindowUnload);
  },

  computed: {
    ...sync({
      activePropertyId: "ProposalViewer/activePropertyId",
      activeProperty: "ProposalViewer/activeProperty"
    }),
    ...get({
      activeProposal: "ProposalViewer/activeProposal",
      userData: "User/userData"
    }),
    isDashboard() {
      return this.userData && this.userData.isAgent;
    },
    linkIsExpired() {
      try {
        if (!this.activeProposal || !this.activeProposal.expiration)
          return false;
        const linkExpirationRaw =
          this.activeProposal && this.activeProposal.expiration
            ? this.activeProposal.expiration
            : null;

        if (linkExpirationRaw) {
          const linkExpiration = new Date(linkExpirationRaw);
          const today = new Date();

          return today >= linkExpiration;
        } else throw new Error("error determining if link is expired");
      } catch (err) {
        return false;
      }
    }
  },
  methods: {
    ...call({
      setActiveProposal: "ProposalViewer/setActiveProposal",
      changeActivePropertyTo: "ProposalViewer/changeActivePropertyTo",
      fireEvent: "Analytics/fireEvent"
    }),
    async init() {
      try {
        if (
          this.$route.params &&
          this.$route.params.agencyId &&
          this.$route.params.id
        ) {
          this.agencyId = this.$route.params.agencyId;
          this.proposalId = this.$route.params.id;
          // First set active proposal from route query param
          await this.setActiveProposal({
            agency: this.agencyId,
            id: this.proposalId
          });

          // if we have properties to how, fire Analytics:
          // 1. Enter proposal as a whole
          // 2. Note an event if link is expired

          if (
            this.activeProposal &&
            this.activeProposal.properties &&
            this.activeProposal.properties.length > 0
          ) {
            this.fireEvent({
              source: this.url,
              type: "ENTER_PROPOSAL",
              value: this.url
            });

            if (this.linkIsExpired) {
              this.fireEvent({
                source: this.url,
                type: "PROPOSAL_LINK_EXPIRED",
                value: this.url
              });
            }
          }

          this.navigateToPropertyInUrlQueryParams();

          this.initFinished = true;
          this.scrollToTop();
        }
      } catch (err) {
        console.error(err);
        this.changeActivePropertyTo(0);
      }
    },
    async scrollToTop() {
      setTimeout(async () => {
        console.log("Scrolling to top...");
        await waitForElemToExist("#very-top");
        document.getElementById("very-top").scrollIntoView();
      }, 1000);
    },
    async navigateToPropertyInUrlQueryParams() {
      const { property: propertyId } = this.$route.query;
      if (propertyId && parseInt(propertyId) && parseInt(propertyId) > 0) {
        await this.changeActivePropertyTo(parseInt(propertyId) - 1);
      } else {
        await this.changeActivePropertyTo(0);
      }

      this.scrollToTop();
    },
    beforeWindowUnload(e) {
      try {
        e.preventDefault();
        this.fireEvent({
          source: this.url,
          type: "LEAVE_PROPOSAL",
          value: this.url
        });
        const confirmationMessage =
          "Are you sure you want to close this proposal?";

        (e || window.event).returnValue = confirmationMessage; //Gecko + IE
        return confirmationMessage;
      } catch (err) {
        console.error("Error during window unload", err);
      }
    },

    goToSourcePage(url) {
      console.log("Went to source url", url);
      this.fireEvent({
        source: this.url,
        type: "NAV_TO_AGENCY_SITE_SOURCE_URL",
        value: url
      });
    },
    viewImage(img) {
      this.fireEvent({
        source: img,
        type: "VIEW_IMAGE",
        value: this.activeProperty.url
      });
    },
    translate() {
      this.fireEvent({
        source: this.activeProperty.url,
        type: "TRANSLATE",
        value: this.activeProperty.url
      });
    }
  }
};
</script>

<style lang="scss" scoped>
.break-spaces {
  white-space: break-spaces;
}
</style>
