<template lang="pug">
    v-alert(type="error" outlined border="left" large)
      p
          b Oops, it looks like the link for these properties has already expired
      p These properties may still be available. You can ask your agent to generate a new link for you.
</template>

<script>
export default {
  name: "ProposalIsExpired"
};
</script>
