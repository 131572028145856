<template lang="pug">
.clean-property-page
        DialogImagePreview
        v-card.error(v-if="!elems") Oops, there was a problem reading this property listing

        template(v-if="elems")
            v-row
                v-col(cols="12")
                  .label-wrapper.d-flex.justify-center.align-center.mb-3
                    .display-2.text-center(v-if="label").text-h5.text--disabled {{label}}
                    v-btn(v-if="!translations.title || !translations.description", depressed @click="handleTranslate").ml-2
                      v-icon.mr-1 mdi-translate
                      | Translate
                    v-btn(v-else color="success" text @click="translations = {title: null, description: null}").ml-2 Translated!
                v-col(cols="12", lg="6")
                    v-btn.mb-3(v-if="elems.source", color="accent", text, @click="handleClick")
                        v-icon.mr-2 mdi-arrow-left
                        span Original {{elems.sourceName}} listing
                    
                    
                    .display-2.font-weight-bold.text-h4.text-md-h3.mb-6.ml-5(v-if="elems.title") {{translations.title || elems.title}}
                    .price.text-h3.font-weight-bold.success--text.mb-3.ml-5 {{elems.price}}
                
                    .summary.mt-7.ml-5(v-if="elems.summaryItems && elems.summaryItems.length > 0")
                      v-row(v-for="itm in elems.summaryItems", :key="itm.name").summary-item.text-h5
                        v-col(cols="6")
                            .name.font-weight-bold.text-capitalize {{itm.name}}:
                        v-col(cols="6")
                            .count.ml-1 {{itm.text}}
            
                    
                v-col(v-if="elems.images && elems.images.length > 0", cols="12", lg="6", v-for="img, idx in elems.images", :key="img + '-' + idx")
                    //- a(:href="img", target="_blank")
                    v-img(:class="{'cursor-pointer': $vuetify.breakpoint.mdAndUp}", :src="img", max-width="800px" eager, :lazy-src="lazySrc" @click="$vuetify.breakpoint.mdAndUp ? handleClickImage(img) : null")
                      template(#placeholder)
                        img(:src="lazySrc")

                    template(v-if="idx===0")
                      v-card.description.break-spaces.my-4.pa-5.grey.lighten-4(flat, v-if="elems.description && elems.description.length > 2")
                        .description-content
                          template(v-if="translations.description") {{translations.description}}
                          template(v-else)
                            span {{translations.description}} {{descriptionIsExpanded ? elems.description : elems.description.substr(0,maxDescriptionLength) + '...'}}
                            .text-center
                              v-btn(v-if="!descriptionIsExpanded", text @click="descriptionIsExpanded=true").mt-3
                                | Read More
                                v-icon.ml-2 mdi-chevron-down

                      v-btn.original-link(x-large block color="primary lighten-4" depressed :href="elems.url" target="_blank" rel="noopener" @click="$emit('click:navToAgencySiteSourceUrl', elems.url)")
                        v-icon.mr-2 mdi-link
                        | Visit Source page
</template>

<script>
import DialogImagePreview from "@/components/Cleaner/DialogImagePreview";
import { sync, call } from "vuex-pathify";
export default {
  name: "CleanPropertyPage",
  components: { DialogImagePreview },
  props: {
    elems: { type: Object, required: true },
    lazySrc: { type: String, default: null },
    label: { type: String, default: null }
  },
  data() {
    return {
      maxDescriptionLength: 200,
      descriptionIsExpanded: false,
      translations: {
        title: "",
        description: ""
      }
    };
  },

  created() {
    if (this.elems.description.length <= this.maxDescriptionLength)
      this.descriptionIsExpanded = true;
  },
  computed: {
    ...sync({
      showPreviewImage: "Cleaner/showPreviewImage",
      previewImage: "Cleaner/previewImage"
    })
  },
  methods: {
    ...call({ translate: "Cleaner/translate" }),
    handleClick() {
      console.log("handling click for elems", this.elems.url);
      window.open(this.elems.url, "_blank");
    },
    handleClickImage(img) {
      this.$emit("click:image", img);
      this.previewImage = img;
      this.showPreviewImage = true;
    },
    async handleTranslate() {
      this.$emit("click:translate");
      const elemTypes = ["title", "description"];
      try {
        for (const elemType of elemTypes) {
          console.log("handling elem type", elemType);
          this.translations[elemType] =
            "Now translating this property " + elemType + "...";
          this.translations[elemType] = await this.translate({
            text: this.elems[elemType]
          });
        }
      } catch (err) {
        console.error(err);
        elemTypes.map(elemType => (this.translations[elemType] = null));
      }
    }
  }
};
</script>

<style lang="scss" scoped>
.break-spaces {
  white-space: break-spaces;
}

.cursor-pointer {
  cursor: pointer;
}
</style>
