<template lang="pug">
    v-card.notes.break-spaces.text-h6.yellow.lighten-4.pa-5(flat).mb-12(v-if="activeProposal")
      v-icon(x-large).mr-2 mdi-comment-quote
      | {{activeProposal.branding.name || 'Your agent'}} says: {{activeProposal.notes[activePropertyId]}}
</template>

<script>
import { get } from "vuex-pathify";
export default {
  name: "PropertyNotes",
  computed: {
    ...get({
      activeProposal: "ProposalViewer/activeProposal",
      activePropertyId: "ProposalViewer/activePropertyId"
    })
  }
};
</script>
