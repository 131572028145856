<template lang="pug">
    .buttons.d-flex.flex-column.flex-md-row.justify-center.justify-md-space-between.mt-12
      v-btn(text color="accent" @click="goToPrevious", :disabled="activePropertyId === 0") 
          v-icon.mr-1 mdi-arrow-left
          span {{activePropertyId > 0 ? 'Back to property ' + (activePropertyId) : 'This is the first property' }}
      v-btn(text color="success" @click="goToNext" :disabled="activePropertyId >= activeProposal.properties.length -1")
          span {{activePropertyId >= activeProposal.properties.length -1 ? 'This is the last property' : 'Forward to Property ' + (activePropertyId + 2)}}
          v-icon.ml-1 mdi-arrow-right
</template>

<script>
import { get, call } from "vuex-pathify";
export default {
  name: "ButtonsPrevNext",
  computed: {
    ...get({
      activeProposal: "ProposalViewer/activeProposal",
      activePropertyId: "ProposalViewer/activePropertyId"
    })
  },
  methods: {
    ...call({
      changeActivePropertyTo: "ProposalViewer/changeActivePropertyTo"
    }),

    goToNext() {
      if (this.activePropertyId < this.activeProposal.properties.length) {
        this.changeActivePropertyTo(this.activePropertyId + 1);
      }
    },
    goToPrevious() {
      if (this.activePropertyId > 0) {
        this.changeActivePropertyTo(this.activePropertyId - 1);
      }
    }
  }
};
</script>
