<template lang="pug">
    v-container
      v-card.pa-5(outlined).mx-auto
          h1.mb-3 Oops, it looks like this proposal link was not found or it has expired
          p Please ask your agent to create a new proposal link for you to view
</template>

<script>
export default {
  name: "NotFound"
};
</script>
